import React from 'react';
import { MailPhone, FooterLocationsContainer, FooterLocationsHeader, FooterLocationsWrapper, } from '@@Components/Footer/styled';
export const FooterLocations = () => {
    const locations = [
        {
            place: 'Warszawa Piaseczno',
            number: '(22) 129 23 44',
            mail: 'salon.piaseczno@libertymotors.pl',
        },
        {
            place: 'Warszawa Połczyńska',
            number: '(22) 129 23 55',
            mail: 'salon.warszawa@libertymotors.pl',
        },
        {
            place: 'Łódź',
            number: '(42) 214 47 70',
            mail: 'salon.lodz@libertymotors.pl',
        },
        {
            place: 'Wrocław',
            number: '(71) 889 88 18',
            mail: 'salon.wroclaw@libertymotors.pl',
        },
        {
            place: 'Gdańsk',
            number: '(58) 888 15 75',
            mail: 'salon.gdansk@libertymotors.pl',
        },
    ];
    return (React.createElement(FooterLocationsContainer, null, locations.map((item, i) => {
        return (React.createElement(FooterLocationsWrapper, { key: i },
            React.createElement(FooterLocationsHeader, null, item === null || item === void 0 ? void 0 : item.place),
            React.createElement(MailPhone, { href: `tel:${item === null || item === void 0 ? void 0 : item.number.replace(/\s/g, '')}`, paddingBottom: '2px' }, item === null || item === void 0 ? void 0 : item.number),
            React.createElement(MailPhone, { href: `mailto:${item === null || item === void 0 ? void 0 : item.mail.replace(/\s/g, '')}`, paddingBottom: '20px' }, item === null || item === void 0 ? void 0 : item.mail)));
    })));
};
